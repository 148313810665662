<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('externalTraining.bill_receive') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="6" sm="6">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_type_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.training_type_id"
                      :options="trainingTypeList"
                      id="training_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      disabled
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                </b-col>
                <!-- <b-col lg="6" sm="6">
                    <ValidationProvider name="Batch" vid="batch_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="batch_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.batch_id"
                          :options="batchList"
                          id="batch_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col> -->
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="12" sm="12" class="text-right">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            <!-- <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="fiscal_year_id"
              >
                <template v-slot:label>
                  {{$t('elearning_config.fiscal_year')}}
                </template>
                <v-select name="organization"
                  v-model="search.fiscal_year_id"
                  label="text"
                  :reduce="item => item.value"
                  :options= fiscalYearList
                  :placeholder="$t('globalTrans.select')"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('elearning_config.organization')"
                  label-for="organization"
              >
                <v-select name="organization"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= orgList
                    :placeholder="$t('globalTrans.select')"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_type_id"
              >
                <template v-slot:label>
                  {{$t('elearning_config.training_type')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.training_type_id"
                  :options="trainingTypeList"
                  id="training_type_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_category_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.training_category')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.training_category_id"
                    :options="trainingCategoryList"
                    id="training_category_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_title_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.training_title')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.training_title_id"
                    :options="trainingTitleList"
                    id="training_title_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="12" sm="12" class="text-right">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col> -->
        </b-row>
      </template>
    </card>
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('externalTraining.bill_receive') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction1>

      </template>
      <template v-slot:headerAction>
          <!-- <b-button variant="primary" @click="pdfExport" class="mr-2">
            {{  $t('globalTrans.export_pdf') }}
          </b-button> -->
          <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                {{  $t('globalTrans.add_new') }}
          </b-button> -->
      </template>
      <template v-slot:body>
        <!-- <select-column-check :labelData="labelData" :search="search" :columns="columns" /> -->
        <b-overlay :show="loadingState">
          <b-row>
                    <b-col sm="12">
                    <div class="quick-filter-wrapper">
                        <div class="quick-filter-left">
                        <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                            <b-dropdown-form>
                            <div
                                class="form-group"
                                v-for="(field, index) in labelData"
                                :key="index"
                                >
                                <b-form-checkbox
                                    :id="'checkbox-' + field.label"
                                    v-model="field.show"
                                    :name="'checkbox-' + field.label"
                                    value=1
                                    unchecked-value=0
                                >
                                    {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                </b-form-checkbox>
                            </div>
                            </b-dropdown-form>
                        </b-dropdown>
                        </div>
                        <div class="quick-filter-right">
                        <b-form-group
                            :label="$t('menu.perpage')"
                            label-for="per-page-select"
                        >
                            <b-form-select
                            id="per-page-select"
                            v-model="search.limit"
                            :options="pageOptions"
                            size="sm"
                            ></b-form-select>
                        </b-form-group>
                        </div>
                    </div>
                    </b-col>
                </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
                <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                  <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                    <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + 1) }}
                  </template>
                    <template v-slot:cell(type_name)="data">
                      <span class="capitalize">{{ data.item.type_name }}</span>
                    </template>
                    <template v-slot:cell(payment_type)="data">
                      <slot v-if="$i18n.locale === 'bn'">
                        <slot v-if="data.item.payment_type === 'Trainee'">
                          প্রশিক্ষণার্থী
                        </slot>
                        <slot v-else>
                          প্রশিক্ষক
                        </slot>
                      </slot>
                      <slot v-else>
                        <span class="capitalize">
                          {{ data.item.payment_type }}
                        </span>
                      </slot>
                    </template>
                    <template v-slot:cell(payment_amount)="data">
                      <span class="capitalize">{{ $n(data.item.payment_amount) }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-4 variant="btn btn-xs btn-success button-customize-for-font" size="sm" @click="edit(data.item)">
                        <i class="fas fa-receipt"></i> {{$t('globalTrans.receipt')}}
                      </b-button>
                    </template>
                  </b-table>
                </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- <pre>{{ listData }}</pre> -->
    </body-card>
  </div>
</template>
<!-- <template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_venue.bill_receive') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_venue.bill_receive') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                          <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                    <template v-slot:cell(index)="data">
                                    {{ $n(data.index + 1) }}
                                    </template>
                                    <template v-slot:cell(type_name)="data">
                                      <span class="capitalize">{{ data.item.type_name }}</span>
                                    </template>
                                    <template v-slot:cell(payment_type)="data">
                                      <slot v-if="$i18n.locale === 'bn'">
                                        <slot v-if="data.item.payment_type === 'Trainee'">
                                          প্রশিক্ষণার্থী
                                        </slot>
                                        <slot v-else>
                                          প্রশিক্ষক
                                        </slot>
                                      </slot>
                                      <slot v-else>
                                        <span class="capitalize">
                                          {{ data.item.payment_type }}
                                        </span>
                                      </slot>
                                    </template>
                                    <template v-slot:cell(payment_amount)="data">
                                      <span class="capitalize">{{ $n(data.item.payment_amount) }}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                      <b-button v-b-modal.modal-4 variant="btn btn-xs btn-success button-customize-for-font" size="sm" @click="edit(data.item)">
                                        <i class="fas fa-receipt"></i> {{$t('elearning_venue.receipt')}}
                                      </b-button>
                                    </template>
                                </b-table>
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                          </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_venue.receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
              </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template> -->
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentBoucher, circularData, circularPublications } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import { mapGetters } from 'vuex'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
          // search: {
          //   org_id: 0,
          //   training_type_id: 0,
          //   training_category_id: 0,
          //   training_title_id: 0,
          //   fiscal_year_id: 0
          // },
          search: {
            org_id: 0,
            // batch_id: 0,
            circular_memo_no: 0,
            training_type_id: 0,
            training_title_id: 0,
            fiscal_year_id: 0,
            limit: 20
          },
          // org_id: this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.professional_info.org_id,
          trainingCategoryList: [],
          trainingTitleList: [],
          circularList: [],
          labelData: [
            { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
            { labels: 'globalTrans.type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
            { labels: 'globalTrans.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
            { labels: 'globalTrans.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
            { labels: 'globalTrans.designation', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
            { labels: 'globalTrans.payment_amount', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
            { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 7, thStyle: { width: '15%' } }
          ]
        }
    },
    computed: {
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      trainingTypeList: function () {
        return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'serial' },
          { key: 'payment_type' },
          { key: 'fiscal_year_bn' },
          { key: 'org_bn' },
          { key: 'designation_name_bn' },
          { key: 'payment_amount' },
          { key: 'action' }
          ]
      } else {
          keys = [
          { key: 'serial' },
          { key: 'payment_type' },
          { key: 'fiscal_year' },
          { key: 'org' },
          { key: 'designation_name' },
          { key: 'payment_amount' },
          { key: 'action' }
          ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    }
      // columns () {
      //     const labels = [
      //         { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
      //         { label: this.$t('globalTrans.type'), class: 'text-left' },
      //         { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
      //         { label: this.$t('elearning_config.organization'), class: 'text-left' },
      //         { label: this.$t('globalTrans.designation'), class: 'text-left' },
      //         { label: this.$t('globalTrans.payment_amount'), class: 'text-left' },
      //         { label: this.$t('globalTrans.action'), class: 'text-center' }
      //     ]

      //     let keys = []

      //     if (this.$i18n.locale === 'bn') {
      //         keys = [
      //         { key: 'index' },
      //         { key: 'payment_type' },
      //         { key: 'fiscal_year_bn' },
      //         { key: 'org_bn' },
      //         { key: 'designation_name_bn' },
      //         { key: 'payment_amount' },
      //         { key: 'action' }
      //         ]
      //     } else {
      //         keys = [
      //         { key: 'index' },
      //         { key: 'payment_type' },
      //         { key: 'fiscal_year' },
      //         { key: 'org' },
      //         { key: 'designation_name' },
      //         { key: 'payment_amount' },
      //         { key: 'action' }
      //         ]
      //     }

      //     return labels.map((item, index) => {
      //         return Object.assign(item, keys[index])
      //     })
      // }
    },
    async created () {
      this.getCircularList()
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.currentFiscalYearId
        })
        this.loadData()
    },
    // async created () {
    //   this.loadData()
    // },
     mounted () {
    },
    watch: {
      // 'search.org_id': function (newValue) {
      //   this.batchList = this.getBatchList(newValue)
      // },
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.circular_memo_no': function (newValue) {
        this.getCircularMemoNo(newValue)
      },
      'search.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    // watch: {
    //   'search.training_type_id': function (newValue) {
    //     this.trainingCategoryList = this.getCategoryList(newValue)
    //   },
    //   'search.training_category_id': function (newValue) {
    //     this.trainingTitleList = this.getTrainingTitleList(newValue)
    //   }
    // },
    methods: {
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_type_id === categoryId)
        }
        return trainingTitleList
      },
      async getCircularMemoNo (getCircularMemoNo) {
      this.load = true
      const params = {
        circular_memo_no: getCircularMemoNo,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularData, params)
      if (!result.success) {
        this.search.org_id = 0
        this.search.training_title_id = 0
        this.search.training_type_id = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.search.org_id = trData.org_id
        this.search.training_title_id = trData.training_title_id
        this.search.training_type_id = trData.training_type_id
        this.load = false
      }
      this.load = false
    },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublications + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      getBatchList (orgId) {
        const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
        if (orgId) {
          return batchList.filter(item => item.org_id === orgId)
        }
        return batchList
      },
      // getCategoryList (typeId) {
      //   const trainingCategoryList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingCategoryList.filter(item => item.status === 1)
      //   if (typeId) {
      //     return trainingCategoryList.filter(item => item.training_type_id === typeId)
      //   }
      //   return trainingCategoryList
      // },
      // getTrainingTitleList (categoryId) {
      //   const trainingTitleList = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.filter(item => item.status === 1)
      //   if (categoryId) {
      //     return trainingTitleList.filter(item => item.training_category_id === categoryId)
      //   }
      //   return trainingTitleList
      // },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, billPaymentBoucher, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const divisionList = this.$store.state.CommonService.commonObj.divisionList
        const districtList = this.$store.state.CommonService.commonObj.districtList
        const upazilaList = this.$store.state.CommonService.commonObj.upazilaList
        const unionList = this.$store.state.CommonService.commonObj.unionList
        const listData = data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const desigObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          const divisionObject = divisionList.find(division => division.value === item.pre_division_id)
          const districtObject = districtList.find(district => district.value === item.pre_district_id)
          const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.pre_upazilla_id)
          const unionObject = unionList.find(union => union.value === item.pre_union_id)
          const divisionData = {
              division_name: typeof divisionObject !== 'undefined' ? divisionObject.text_en : '',
              division_name_bn: typeof divisionObject !== 'undefined' ? divisionObject.text_bn : ''
          }
          const districtData = {
            district_name: typeof districtObject !== 'undefined' ? districtObject.text_en : '',
            district_name_bn: typeof districtObject !== 'undefined' ? districtObject.text_bn : ''
          }
          const upazillaData = {
            upazilla_name: typeof upazilaObject !== 'undefined' ? upazilaObject.text_en : '',
            upazilla_name_bn: typeof upazilaObject !== 'undefined' ? upazilaObject.text_bn : ''
          }
          const unionData = {
            union_name: typeof unionObject !== 'undefined' ? unionObject.text_en : '',
            union_name_bn: typeof unionObject !== 'undefined' ? unionObject.text_bn : ''
          }
          const address = divisionData.division_name + ',' + districtData.district_name + ',' + upazillaData.upazilla_name + ',' + unionData.union_name
          const addressData = {
            address: address
          }
          return Object.assign({}, item, { serial: index }, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData, addressData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
